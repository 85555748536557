import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const PinePage: FC = () => (
  <Layout>
    <SEO title="Stafafura" />
    <h3>Stafafura (<i>Pinus contorta</i> Dougl.)</h3>
    <p>
      Er harðgerð og hraðvaxta frumherjatrjátegund. Æskuvöxtur á bersvæði er
      miðlungsgóður en hægt að auka hann með viðeigandi jarðvinnslu.
    </p>
    <p>
      Þolir trjátegunda best frostlent land og getur á slíkum stöðum vaxið upp
      meðan t.d. íslensk ilmbjörk skemmist og koðnar niður. Þolir illa mikið
      vindálag, sérstaklega ef vindur stendur af hafi (saltskemmdir). Skemmist
      einnig af sólbruna síðvetrar og eru því stafafuruskógar oft vöxtulegri í
      hlíðum sem snúa mót norðri.
    </p>
    <p>
      Vex vel á rýru landi og jafnvel lítt grónu landi. Hentar því ágætlega til
      landgræðslu inn til landsins bæði sunnan og norðan heiða.
    </p>
    <p>
      Bestu svæðin á Íslandi eru: Fljótsdalshérað innanvert, innsveitir
      Suðausturlands, Suðurlandsundirlendis og Borgarfjarðar ásamt austurhlíðum
      Skagafjarðar, innanverðum Eyjafirði og S.-Þingeyjarsýslu.
    </p>
    <p>
      Fjölgar sér ríkulega náttúrulega með fræplöntum. Fræið berst stutt frá
      vaxtarstað (þungt fræ).
    </p>
    <p>
      Borðviðarnýtingarhlutfall er lágt en bolir furunnar nýtast ágætlega í
      iðnvið og kurl.
    </p>
  </Layout>
)

export default PinePage
